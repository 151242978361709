import Select from 'react-select'

import styled from '@emotion/styled'

export const StyledSelect = styled(Select)`
  .react-select__control {
    border: 2px solid
      ${(p) => (p.valid ? '#2BD2B4' : p.isInvalid ? '#D01030' : '#9FB8E5')};
    min-height: 48px;
    border-radius: 6px;
  }
  .react-select__control--is-focused {
    box-shadow: 0 0 0 2px rgba(44, 133, 244, 0.6);
  }
  .react-select__value-container {
    font-weight: 600;
    font-size: 14px;
    padding-left: 16px;
  }
  .react-select__placeholder {
    font-size: 14px;
    color: rgba(45, 46, 65, 0.3);
    font-style: italic;
    font-weight: 500;
  }
  .react-select__single-value {
    overflow: visible;
    padding: 0px 8px;
  }
  .react-select__multi-value {
    border-radius: 4px;
  }
  .react-select__option {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.4px;
  }
  .react-select__indicator-separator {
    background-color: #9fb8e5;
  }
`
