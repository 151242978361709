import { ReactNode, useState } from 'react'

import { Box, Flex, HStack, Text, Tooltip, VStack } from '@chakra-ui/react'
import {
  NumberCountUp,
  TimeFormatCountUp,
} from '@hivewatch/design-system/CountUp'
import { Info } from '@hivewatch/design-system/icon/Info'

import { Card } from '@/components/ui'
import { ListBox } from '@/components/ui'

export type Option = { name: string; value: number }

interface StatCardIProps {
  value: number
  label: string
  icon: ReactNode
  tooltip: string
  options: Option[]
  timeDurationFormat?: boolean
  onSelect?: (Option) => void
  onTooltipHover?: () => void
  onSelectButtonHover?: () => void
  onSelectButtonClick?: () => void
}

export const StatCard = ({
  label,
  icon,
  options,
  tooltip,
  value,
  timeDurationFormat = false,
  onSelect,
  onTooltipHover,
  onSelectButtonHover,
  onSelectButtonClick,
}: StatCardIProps) => {
  // @ts-ignore
  const [selected, setSelected] = useState(options[0] as Option)

  const handleSelection = (newSelection: Option) => {
    setSelected(newSelection)
    onSelect?.(newSelection)
  }

  return (
    <Card
      border='1px'
      borderColor='#D9D9D9'
      borderRadius='8px'
      boxShadow='none'
      pos='relative'
      px='15px'
      py='20px'
      w='full'
    >
      <Flex
        alignItems='center'
        flexDirection={{
          base: 'row',
          lg: 'column',
          xl: 'row',
        }}
        justifyContent='space-between'
        w='full'
      >
        <VStack
          align={{
            base: 'start',
            lg: 'center',
            xl: 'start',
          }}
          isTruncated
        >
          <Text fontSize='12px' fontWeight='medium' isTruncated w='full'>
            {label}
          </Text>
          <HStack alignItems='center'>
            {icon}
            <Text data-testid='Dashboard:counter' fontSize='20px' isTruncated>
              {timeDurationFormat ? (
                <TimeFormatCountUp value={value} />
              ) : (
                <NumberCountUp value={value} />
              )}
            </Text>
          </HStack>
        </VStack>
        <ListBox
          onButtonClick={onSelectButtonClick}
          onButtonHover={onSelectButtonHover}
          options={options}
          selected={selected}
          selectedValuePrefix='Past'
          setSelected={handleSelection}
        />
      </Flex>

      <Tooltip
        bg='#101113'
        fontSize='12px'
        hasArrow
        label={tooltip}
        mx={4}
        p='20px'
        placement='top'
      >
        <Box onMouseEnter={onTooltipHover} pos='absolute' right={2} top={2}>
          <Info color='#9195A2' height={18} width={18} />
        </Box>
      </Tooltip>
    </Card>
  )
}
