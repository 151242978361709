import { Box } from '@chakra-ui/layout'

interface DrawerHeaderIProps {
  header: string
  subHeader?: string
}
export const DrawerHeader = ({ header, subHeader }: DrawerHeaderIProps) => {
  return (
    <>
      <Box
        color='#282424'
        fontSize='24px'
        fontWeight='bold'
        letterSpacing='-0.8px'
        lineHeight='30px'
      >
        {header}
      </Box>
      {subHeader && (
        <Box
          color='#282424'
          fontSize='16px'
          letterSpacing='-0.53px'
          lineHeight='20px'
          mt={2}
        >
          {subHeader}
        </Box>
      )}
    </>
  )
}
