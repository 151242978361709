import { useMemo } from 'react'

import type { AppProps } from 'next/app'

import {
  ApolloClient,
  ApolloLink,
  Context,
  DocumentNode,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/link-error'
import { createUploadLink } from 'apollo-upload-client'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import merge from 'deepmerge'
import { IncomingHttpHeaders } from 'http'
import fetch from 'isomorphic-unfetch'
import isEqual from 'lodash/isEqual'

import { store } from '@/redux/store'
import {
  appSyncAuth,
  appSyncRegion,
  appSyncUrl,
  errorLogger,
} from '@/utils/apollo'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined
const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'
const isBrowser = typeof window !== 'undefined'
const isDev = process.env.NODE_ENV === 'development'

// Determines which Apollo link to use for specific operation
const shouldUseAppSyncLink = ({
  query,
  getContext,
}: {
  query: DocumentNode
  getContext: () => Context
}): boolean => {
  const def = getMainDefinition(query)

  if (getContext()?.target === 'appsync') {
    // Explicit context information
    return true
  } else if (
    // Default link for subscription
    def.kind === 'OperationDefinition' &&
    def.operation === 'subscription'
  ) {
    return true
  }
  return false
}

const createApolloClient = (headers: IncomingHttpHeaders | null = null) => {
  // isomorphic fetch for passing the cookies along with each GraphQL request
  const enhancedFetch = async (url: RequestInfo, init: RequestInit) => {
    const tokenValue = store.getState().token.token

    return fetch(url, {
      ...init,
      headers: {
        ...init.headers,
        Authorization: tokenValue ? `Bearer ${tokenValue}` : '',
        cookie: headers?.cookie ?? '',
      },
    }).then((response) => response)
  }

  // HTTP link for queries/mutation
  const httpLink = ApolloLink.from([
    onError(errorLogger),
    // this uses apollo-link-http under the hood, so all the options here come from that package
    // TO CONNECT TO LOCAL BACKEND
    // USE 'http://localhost:8080/graphql',
    createUploadLink({
      uri: process.env.NEXT_PUBLIC_SERVICE_URL,
      fetch: enhancedFetch,
    }),
  ])

  // Link for AWS AppSync
  const appSyncLink = ApolloLink.from([
    createAuthLink({
      url: appSyncUrl,
      region: appSyncRegion,
      auth: appSyncAuth,
    }),
    createSubscriptionHandshakeLink(
      { url: appSyncUrl, region: appSyncRegion, auth: appSyncAuth },
      new HttpLink({ uri: appSyncUrl })
    ),
  ])

  // Use different links based on operation type
  const splitLink =
    isBrowser && appSyncLink != null && appSyncUrl
      ? split(shouldUseAppSyncLink, appSyncLink, httpLink)
      : httpLink

  return new ApolloClient({
    // Connect to Apollo DevTools
    connectToDevTools: isBrowser && isDev,
    // SSR only for Node.js
    ssrMode: !isBrowser,
    link: splitLink,
    cache: appCache,
  })
}

interface CacheEntryI {
  __ref: string
}

export const appCache = new InMemoryCache({
  typePolicies: {
    Notification: {
      keyFields: ['notificationTimestamp'],
    },
    Query: {
      fields: {
        listNotifications: {
          // Separately cache all notifications and only unread
          keyArgs: [['filter', 'read']],
          merge(existing = { items: [] }, incoming, options) {
            if (options.args.nextToken === undefined) {
              return incoming
            }

            const existingKeys = new Set(
              existing.items?.map((e: CacheEntryI) => e?.__ref)
            )
            const combinedItems = [
              ...existing.items,
              ...incoming.items.filter(
                (e: CacheEntryI) => !existingKeys.has(e?.__ref)
              ),
            ]

            return {
              ...incoming,
              items: combinedItems,
            }
          },
        },
      },
    },
  },
})

type InitialState = NormalizedCacheObject | undefined

interface InitializeApolloI {
  headers?: IncomingHttpHeaders | null
  initialState?: InitialState | null
}

export const initializeApollo = (
  { headers, initialState }: InitializeApolloI = {
    headers: null,
    initialState: null,
  }
) => {
  const _apolloClient = apolloClient ?? createApolloClient(headers)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser) return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: AppProps['pageProps']
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps: AppProps['pageProps']) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(
    () => initializeApollo({ initialState: state }),
    [state]
  )
  return store
}

export const client = createApolloClient()
