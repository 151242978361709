import { FaWrench } from 'react-icons/fa'

import {
  Badge,
  Box,
  Circle,
  Flex,
  Grid,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'

export interface DeviceHealthPopoverIProps {
  deviceIsUnhealthy?: boolean
  lastChecked?: string
  lastDowntime?: string
  handleOrderRepair?: () => void
}

const ListItem = ({
  leftContent,
  rightContent,
}: {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}) => {
  return (
    <Grid
      alignItems='center'
      borderColor='#E5F0FC'
      borderTopWidth='2px'
      h='44px'
      templateColumns='1fr 1fr'
      w='full'
    >
      <Flex>{leftContent}</Flex>
      {rightContent && <Flex>{rightContent}</Flex>}
    </Grid>
  )
}

export const DeviceHealthPopover = ({
  deviceIsUnhealthy,
}: DeviceHealthPopoverIProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <Flex zIndex='9999'>
      <Popover
        closeOnBlur={false}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        placement='bottom-end'
        trigger='hover'
      >
        <PopoverTrigger>
          <Circle
            bg={deviceIsUnhealthy ? 'incident.active.100' : 'brand.100'}
            cursor='pointer'
            data-testid='incidentDrawer_header_deviceHealthIcon'
            size='24px'
          >
            <FaWrench color='#fff' size={10} />
          </Circle>
        </PopoverTrigger>
        <PopoverContent borderWidth='0' boxShadow='dark-lg' p='20px' pb='5px'>
          <HStack align='start' spacing='18px'>
            <Circle
              bg={deviceIsUnhealthy ? 'incident.active.100' : 'brand.100'}
              cursor='pointer'
              size='24px'
            >
              <FaWrench color='#fff' size={10} />
            </Circle>
            <Box>
              <Box
                fontSize='20px'
                fontWeight='bold'
                letterSpacing='-0.67px'
                lineHeight='25px'
                pb='20px'
              >
                Device Health Status
              </Box>
              <ListItem
                leftContent={
                  <Box
                    color='#2D2E41'
                    fontSize='12px'
                    fontWeight='semibold'
                    letterSpacing='-0.4px'
                  >
                    Device State:
                  </Box>
                }
                rightContent={
                  <Badge
                    bg={deviceIsUnhealthy ? 'incident.active.100' : 'brand.100'}
                    borderRadius='5px'
                    data-testid='incidentDrawer_header_deviceHealthStatus'
                    fontSize='12px'
                    letterSpacing='-0.4px'
                    lineHeight='15px'
                    px='15px'
                    py='6px'
                    textShadow='lg'
                    textTransform='capitalize'
                    variant='solid'
                  >
                    {deviceIsUnhealthy ? 'Unhealthy' : 'Healthy'}
                  </Badge>
                }
              />
            </Box>
          </HStack>
          <PopoverArrow />
        </PopoverContent>
      </Popover>
    </Flex>
  )
}
