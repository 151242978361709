import {
  FacilityTypesQuery,
  OutstandingIncidentsGuardsQuery,
  OutstandingIncidentsOperatorsQuery,
  PacsSourcesQuery,
  TechnicianQuery,
  TechniciansQuery,
} from '@/graphql/generated/operations'
import {
  DevicesQuery,
  FacilityFloorsQuery,
  FacilityNamesQuery,
  StandardOperatingProceduresFilterDataQuery,
} from '@/graphql/generated/operations'

import { getArchivedLabelWithSN } from '../formats'

export interface OptionI {
  label: string
  value: string
}

type Options = Array<OptionI>

export const getIncidentFacilityOptions = (
  facilitiesData: FacilityTypesQuery
): Options =>
  facilitiesData?.facilities?.edges.map((f) => ({
    label: `${f?.node?.name} - (${f?.node?.shortName})`,
    value: f?.node?.id,
  })) || []

export const getFacilityOptions = (
  facilitiesData: FacilityNamesQuery
): Options =>
  facilitiesData?.facilityNames?.edges.map((f) => getFacilityOption(f?.node)) ||
  []

export const getTechnicianFacilityOptions = (
  techData: TechnicianQuery
): Options =>
  techData?.technician?.facilities?.map((f) => getFacilityOption(f)) || []

export const getFacilityOption = (facilityNode: {
  name?: string
  shortName?: string
  archived?: boolean
  id: string
}): OptionI => ({
  label: getArchivedLabelWithSN(facilityNode),
  value: facilityNode?.id,
})

export const getFloorOptions = (floorsData: FacilityFloorsQuery): Options =>
  floorsData?.facilityFloors?.edges.map((f) => ({
    label: `${f?.node?.name} - (${f?.node?.facility?.name})`,
    value: f?.node?.id,
  }))

export const getDeviceOptions = (devicesData: DevicesQuery): Options =>
  devicesData?.devices?.edges.map((f) => ({
    label: `${f?.node?.name} - (${f?.node?.facility?.name})`,
    value: f?.node?.id,
  })) || []

export const getPacsOptions = (pacsData: PacsSourcesQuery): Options =>
  pacsData?.pacsSources?.edges.map((f) => ({
    label: `${f?.node?.name}`,
    value: f?.node?.id,
  })) || []

export const getSOPCreatorOptions = (
  sopData: StandardOperatingProceduresFilterDataQuery
): Options =>
  sopData?.standardOperatingProcedures.edges.map((s) => ({
    label: `${s?.node?.creator?.firstName} ${s?.node?.creator?.lastName}`,
    value: s?.node?.creator.id,
  })) || []

export const getTechnicianOptions = (data: TechniciansQuery): Options =>
  data?.technicians?.edges.map((f) => ({
    label: `${f?.node?.firstName} ${f?.node?.lastName} (${f?.node?.email})`,
    value: f?.node?.id,
  })) || []

export const getOutstandingIncidentsGuardsOptions = (
  data: OutstandingIncidentsGuardsQuery
): Options =>
  data?.guards?.edges.map((f) => ({
    label: `${f?.node?.firstName} ${f?.node?.lastName}`,
    value: f?.node?.id,
  })) || []

export const getOutstandingIncidentsOperatorsOptions = (
  data: OutstandingIncidentsOperatorsQuery
): Options =>
  data?.operators?.edges.map((f) => ({
    label: `${f?.node?.firstName} ${f?.node?.lastName}`,
    value: f?.node?.id,
  })) || []
